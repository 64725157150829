<template>
  <div>
    <div class="text-center">
      HAPPY GO登入
    </div>
  </div>
</template>

<script>
import { loginBySSO } from '@/api/user'
import { siteLogin } from '@/utils/user'
import { showNotice } from '@/utils/tool'

export default {
  name: 'HappyGoSSOReturn',
  data() {
    return {}
  },
  created() {
    this.$store.commit('SET_LOADING', true)
    const self = this

    if (this.$route.query.token) {
      // 使用happyGo token登入3T
      loginBySSO(this.$route.query.token).then(response => {
        self.$store.commit('SET_LOGIN_TYPE', 'happygo')
        siteLogin(response, atob(this.$route.params.redirect))
      })
    } else {
      showNotice('HappyGo登入失敗(token) 請重新嘗試', function() {
        self.$router
          .push({ path: atob(self.$route.params.redirect) })
          .catch(() => {})
      })
    }
  },
  destroyed() {
    this.$store.commit('SET_LOADING', false)
  }
}
</script>
